<template>
  <div class="eva-creative">
    <el-page-header @back="goBack">
      <div class="fwb" slot="content">创新型企业申报自评价</div>
    </el-page-header>
    <el-divider></el-divider>
    <div v-if="!resultVisible" class="eva-creative-content" v-loading="conditionLoading">
      <div class="fwb  mb-4">一、评价得分达到 60 分以上（其中创新能力指标得分不低
        于 20 分、成长性指标及专业化指标得分均不低于 15 分）， 或满足下列条件之一
      </div>
      <el-form :model="conditionForm" label-position="left">
        <el-form-item class="txt-title" :prop="condition.prop" v-for="(condition) in conditions"
                      :label="condition.conditionDesc" :key="condition.id" :rules="{required: true, message: '该项为必选！', trigger: 'blur'}">
          <el-radio-group v-model="conditionForm[condition.prop]">
            <el-radio v-for="option in options" :label="option.value" :key="option.value">{{ option.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template v-if="indicatorVisible">
        <div class="fwb mb-4">二、评价指标</div>
        <div class="mb-4 txt-title">包括创新能力、成长性、专业化三类六个指标，评价结
          果依分值计算，满分为 100 分。
        </div>
        <el-form :model="indicatorForm" label-position="top" :rulse="{required: true,message: '该项为必选！',trigger: 'blur'}">
          <div v-for="group in indicatorGroups" :key="group.id">
            <div class="fwb mb-4">{{ group.groupDesc }}</div>
            <el-form-item class="txt-title" v-for="(indicator, index) in group.indicators"
                          :label="`${index + 1}、${indicator.indicatorDesc}`" :key="indicator.id" :prop="indicator.prop">
              <el-radio-group v-model="indicatorForm[indicator.prop]" class="wi100">
                <div class="wi100" style="display: flex; justify-content: flex-start;flex-wrap: wrap;line-height: 2rem">
                  <div class="lh2 wi25" v-for="option in indicator.options" :key="option.id">
                    <el-radio :label="option.score">{{ option.optionDesc }}</el-radio>
                  </div>
                </div>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <div class="tac">
        <el-button @click="handleSubmit" type="primary" size="small" icon="el-icon-edit">提交</el-button>
      </div>
    </div>
    <div v-if="resultVisible">
      <el-result v-if="isQualified" icon="success" title="自评价结果" subTitle="恭喜您，您所评测的企业符合申报专精特新‘小巨人’企业的条件！">
        <div slot="extra" class="wi100">
          <el-button type="primary" @click="goBackEvaluation">返回</el-button>
          <el-divider></el-divider>
          <div class="tal">为您推荐以下服务：</div>
          <div class="uf wi100">
            <service-card class="animated zoomIn" v-for="service in recommendServices" :key="service.id" :service="service" @showDetail="showServiceDetail(service)"></service-card>
          </div>
        </div>
      </el-result>
      <el-result v-else icon="error" title="自评价结果" subTitle="很抱歉，您的企业暂时不符合申报专精特新‘小巨人’企业的条件！">
        <div slot="extra">
          <el-button type="primary" @click="goBackEvaluation">返回</el-button>
        </div>
      </el-result>
    </div>
  </div>
</template>

<script>
import ServiceCard from '../../components/service-card'
export default {
  name: 'evaluate',
  components: {
    ServiceCard
  },
  data() {
    return {
      loading: false,
      resultVisible: false,
      // 是否显示指标
      indicatorVisible: false,
      conditions: [],
      // 直通条件
      conditionForm: {},
      // 打分指标
      indicatorForm: {},
      options: [
        {
          value: '1',
          label: '是'
        },
        {
          value: '0',
          label: '否'
        }
      ],
      // 指标组
      indicatorGroups: [],
      // 是否符合
      isQualified: false,
      recommendServices: [],
      recommendLoading: false,
      conditionLoading: false,
    }
  },
  created() {
    this.initConditions()
    this.initIndicatorGroups()
  },
  watch: {
    conditionForm: {
      handler: function (data) {
        let sum = 0
        for (let key in data) {
          sum += parseInt(data[key])
        }
        if (sum === 0) {
          this.indicatorVisible = true
        } else {
          this.indicatorVisible = false
        }
      },
      deep: true
    }
  },
  methods: {
    /**
     * 返回
     */
    goBack() {
      this.$router.go(-1)
    },
    /**
     * 初始化直通条件
     */
    initConditions() {
      this.conditionLoading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.EVALUATION.CREATIVE.CONDITIONS),
        method: 'GET'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.conditions = data.list || []
        }
        this.conditionLoading = false
      })
    },
    /**
     * 初始化指标
     */
    initIndicatorGroups() {
      this.$http({
        url: this.$http.adornUrl(this.$api.EVALUATION.CREATIVE.INDICATOR_GROUPS),
        method: 'GET'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.indicatorGroups = data.groups || []
        }
      })
    },
    handleSubmit() {
      let conditionForm = {...this.conditionForm}
      let conditionSum = 0
      for (let key in conditionForm) {
        conditionSum += conditionForm[key]
      }
      if (conditionSum > 0) {
        this.isQualified = true
        this.resultVisible = true
        this.listRecommendService()
        return
      }
      let indicatorForm = {...this.indicatorForm}
      let creativeSum = parseInt(indicatorForm['knowledgeCount']) + parseInt(indicatorForm['proportion'])
      let incrementSum = parseInt(indicatorForm['growthRate']) + parseInt(indicatorForm['debtRatio']) + parseInt(indicatorForm['field']) + parseInt(indicatorForm['income'])
      let totalSum = creativeSum + incrementSum
      if (creativeSum >= 20 && incrementSum >= 15 && totalSum >= 60) {
        this.isQualified = true
        this.listRecommendService()
      } else {
        this.isQualified = false
      }
      this.resultVisible = true
    },

    // 查询推荐服务
    listRecommendService() {
      this.recommendLoading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SEARCH),
        method: 'POST',
        params: this.$http.adornParams({
          itemCode: this.$cons.ZJTX_SERVICE_ITEM_CODE,
          page: 1,
          limit: 10
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.recommendServices = data.page.list || []
        }
        this.recommendLoading = false
      })
    },
    // 选择产品
    showServiceDetail(item) {
      this.$router.push({name: 'service', query: {id: item.id}})
    },
    goBackEvaluation () {
      this.resultVisible = false
    }

  }
}
</script>

<style scoped>
.creative-bg {
  width: 70%;
  min-height: 800px;
  margin: 10px auto;
  padding: 1rem 1rem;
  background-color: #FFFFFF;
}

.eva-creative {
  background-color: #FFFFFF;
  min-height: 800px;
  padding: 2rem;
}

.txt-title {
  text-indent: 2rem;
}

.eva-creative-content {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem 0;
}
</style>
